import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  SelectionOption,
  CalMaxIcon,
  StyleText,
  StyleDescription,
  ColorContainer,
  PriceContainer,
} from 'src/templates/CalendarProductPage/styles'
import { useStyleConfig } from '../../templates/SnapFramePage/styleConfig'
import ColorSelectionItem from '../ColorSelectionItem'

const StyleOption = ({ style, color, isSelected, handleSelection, smallRadio, plp }) => {
  const styleConfig = useStyleConfig()
  const {
    description,
    basePrice,
    snapBasePrice,
    prices,
    defaultPrices,
    snapPrices,
    snapDefaultPrices,
  } = styleConfig[style]
  const selectedPriceSource = plp ? prices : snapPrices
  const selectedDefaultPrices = plp ? defaultPrices : snapDefaultPrices

  const selectedPrices = isSelected ? selectedPriceSource[color] : selectedDefaultPrices
  const { originalPrice, discountedPrice } = selectedPrices

  const displayedPrice = originalPrice !== 'NONE' ? originalPrice : plp ? basePrice : snapBasePrice
  let displayedDiscountedPrice =
    discountedPrice !== 'NONE' ? discountedPrice : plp ? basePrice : snapBasePrice
  if (discountedPrice === 'NONE') {
    displayedDiscountedPrice = null
  }
  const images = plp ? styleConfig[style]?.image : styleConfig[style]?.snapImage
  const image = isSelected && images[color] ? images[color] : images[Object.keys(images)[0]]
  return (
    <SelectionOption
      selected={isSelected}
      key={color}
      onClick={() => handleSelection(style, color)}
    >
      <CalMaxIcon>
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={`${style} ${color} Frame 2`}
        />
      </CalMaxIcon>
      <ColorContainer>
        <StyleText>{style}</StyleText>
        {Object.keys(images).map((colorKey, i) => (
          <ColorSelectionItem
            key={`${i}-${colorKey}-${smallRadio}`}
            variant={colorKey}
            isOutOfStock={false}
            isSelected={color === colorKey}
            smallRadio={smallRadio}
            onSelectColor={() => handleSelection(style, colorKey)}
          />
        ))}
      </ColorContainer>
      <PriceContainer>
        <StyleText blue isDiscounted={displayedDiscountedPrice}>
          ${displayedPrice}
        </StyleText>
        {displayedDiscountedPrice && (
          <StyleText blue red>
            ${displayedDiscountedPrice}
          </StyleText>
        )}
      </PriceContainer>
      <StyleDescription>{description}</StyleDescription>
    </SelectionOption>
  )
}

StyleOption.propTypes = {
  style: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleSelection: PropTypes.func.isRequired,
  smallRadio: PropTypes.bool,
  plp: PropTypes.bool,
}

export default StyleOption
