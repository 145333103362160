import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'gatsby-plugin-react-i18next'
import {
  Subheader,
  FrameProductOptions,
  ButtonContainer,
} from 'src/components/FrameProductForm/styles'
import {
  RightGrid,
  StyleSelectionContainer,
  CTAContainer,
} from '../../templates/SnapFramePage/styles.js'
import {
  SubLabel,
  StyleOptionsContainer,
  ComingSoonDisclosure,
} from '../../templates/CalendarProductPage/styles'
import ColorSelectionItem from '../ColorSelectionItem'
import StyleOption from '../StyleOption'
import QuantitySelection from '../QuantitySelection'
import AddToCartButton from '../AddToCartButton'

const Frame2ColorSelection = ({
  style,
  color,
  handleStyleSelection,
  handleColorSelection,
  styleConfig,
  stylesOrder,
  scrollableStylesContainerRef,
  OOS,
  shipDate,
  quantityVal,
  decrementQuantity,
  incrementQuantity,
  submitting,
  handleAddToCart,
  shopifyId,
  plp,
}) => (
  <RightGrid plp={plp}>
    <Subheader>
      <Trans>Select a style and color</Trans>
    </Subheader>
    <StyleSelectionContainer ref={scrollableStylesContainerRef}>
      <StyleOptionsContainer>
        {styleConfig &&
          Object.keys(styleConfig).length > 0 &&
          stylesOrder.map((styleKey, i) => (
            <StyleOption
              key={`${styleKey}-${color}-${i}`}
              style={styleKey}
              color={color}
              isSelected={style === styleKey}
              handleSelection={(selectedStyle) => {
                if (style !== selectedStyle) {
                  handleStyleSelection(selectedStyle)
                }
              }}
              smallRadio
              isOutOfStock={styleConfig[styleKey].oos}
              plp={plp}
            />
          ))}
      </StyleOptionsContainer>
    </StyleSelectionContainer>
    <SubLabel />
    <FrameProductOptions>
      {Object.keys(styleConfig[style].image).map((colorKey, i) => (
        <ColorSelectionItem
          key={`style-${color}-${i}`}
          variant={colorKey}
          onSelectColor={() => handleColorSelection(colorKey)}
          isOutOfStock={false}
          isSelected={color === colorKey}
        />
      ))}
    </FrameProductOptions>
    {OOS && (
      <ComingSoonDisclosure>
        Shipping delay: This item is estimated to ship by {shipDate}
      </ComingSoonDisclosure>
    )}
    <QuantitySelection
      quantityVal={quantityVal}
      decrementQuantity={decrementQuantity}
      incrementQuantity={incrementQuantity}
    />
    <CTAContainer plp={plp}>
      <ButtonContainer className="regular-cta">
        <AddToCartButton
          label="Add to Cart"
          adding={submitting}
          handleClick={() => handleAddToCart(shopifyId, quantityVal)}
          oos={false}
        />
      </ButtonContainer>
    </CTAContainer>
  </RightGrid>
)

Frame2ColorSelection.propTypes = {
  style: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  handleStyleSelection: PropTypes.func.isRequired,
  handleColorSelection: PropTypes.func.isRequired,
  styleConfig: PropTypes.object.isRequired,
  stylesOrder: PropTypes.array.isRequired,
  scrollableStylesContainerRef: PropTypes.object.isRequired,
  OOS: PropTypes.bool.isRequired,
  shipDate: PropTypes.string,
  quantityVal: PropTypes.number.isRequired,
  decrementQuantity: PropTypes.func.isRequired,
  incrementQuantity: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  shopifyId: PropTypes.string.isRequired,
  plp: PropTypes.bool,
}

export default Frame2ColorSelection
